import { useParams, useNavigate } from "react-router-dom";
import CardLayout from "../../../components/Layout/CardLayout";
import request from "../../../request/request";
import useFetch from "../../../hooks/useFetch";
import LoaderLayout from "../../../components/Loader/LoaderLayout";
import PagesFormContainer from "../../../components/Form/dynamicPages/PagesFormContainer";

const EditPages = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // fetch data

  const { result, isLoading } = useFetch(
    () => request.getOne("dynamic-pages/get-one", id),
    id
  );

  // update handler function
  const updatePages = async (formValues) => {
    let imageData = null;
    if (formValues.page_banner?.name) {
      const formData = new FormData();

      formData.append("page_banner", formValues.page_banner);

      imageData = await request.imageUpload(
        "dynamic-pages/upload/images",
        formData
      );
      console.log(imageData);
    }
    formValues.page_banner =
      imageData?.data?.find((file) => file?.fieldname === "page_banner")
        ?.filename || result?.page_banner;

    await request.updateOne("dynamic-pages", id, formValues, () => {
      navigate("/dashboard/website/pages");
    });
  };
  return (
    <LoaderLayout isPending={isLoading}>
      <CardLayout title="Edit Pages">
        <PagesFormContainer apiHandler={updatePages} editData={result} />
      </CardLayout>
    </LoaderLayout>
  );
};

export default EditPages;
