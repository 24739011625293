import { useState } from "react";
import { Button } from "react-bootstrap";
import { FaEye } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import DeleteButton from "../../../components/Button/DeleteButton";
import EditButton from "../../../components/Button/EditButton";
import CardLayout from "../../../components/Layout/CardLayout";
import DeleteModal from "../../../components/Modal/DeleteModal";
import ReactTable from "../../../components/ReactDataTable/ReactTable";
import useFetch from "../../../hooks/useFetch";
import request from "../../../request/request";

const PageList = () => {
  const navigate = useNavigate();
  const { result, isLoading, setRefresh } = useFetch(() =>
    request.getAll("dynamic-pages")
  );

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const columns = [
    {
      key: "title",
      text: "Title",
      center: "true",
      wrap: true,
    },
    {
      key: "url",
      text: "Url",
      center: "true",
      wrap: true,
    },
    // {
    //     name: 'le',
    //     center: 'true',
    //     wrap: true,
    //     cell: (row) => <div dangerouslySetInnerHTML={{ __html: row.content }} />,
    // },

    {
      key: "action",
      text: "Action",
      width: "200px",
      cell: (row) => (
        <div>
          <Button
            size="sm"
            variant="warning"
            className="me-2"
            onClick={() =>
              navigate(`/dashboard/website/pages/details/${row?.id}`)
            }
          >
            <FaEye />
          </Button>

          <EditButton
            onClick={() => navigate(`/dashboard/website/pages/edit/${row?.id}`)}
          />
          <DeleteButton
            onClick={() => {
              setModalOpen(true);
              setSelectedItem(row);
            }}
          />
        </div>
      ),
    },
  ];

  const deletePagesHandler = () =>
    request.delete("pages", selectedItem.id, () => {
      setModalOpen(false);
      setSelectedItem(null);
      setRefresh((prev) => !prev);
    });

  return (
    <CardLayout
      title="Dynamic Page List"
      buttonTitle="Add New Page"
      addButtonHandler={() => navigate("/dashboard/website/pages/add")}
    >
      <ReactTable columns={columns} data={result} />

      {isModalOpen && (
        <DeleteModal
          isOpen={isModalOpen}
          onClose={() => {
            setModalOpen(false);
            setSelectedItem(null);
          }}
          handleDelete={deletePagesHandler}
        />
      )}
    </CardLayout>
  );
};

export default PageList;
