import { Form, InputGroup } from "react-bootstrap";
import FormLabel from "../../../components/Text/FormLabel";
import ReactInput from "../../../components/InputList/ReactInput/ReactInput";
import { useFormikContext } from "formik";

const SocialMediaForm = () => {
  const { values, setFieldValue } = useFormikContext();
  const formatDateForInput = (isoDate) => {
    if (!isoDate) return "";
    const date = new Date(isoDate);
    const offset = date.getTimezoneOffset() * 60000; // Adjust for timezone offset
    const localDate = new Date(date.getTime() - offset);
    return localDate.toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:MM
  };
  return (
    <div>
      <InputGroup className="mt-3">
        <FormLabel title={"Name"} required />
        <ReactInput name="name" required />
      </InputGroup>
      <InputGroup className="mt-3">
        <FormLabel title={"Icon"} required />
        <ReactInput name="icon" required />
      </InputGroup>
      <InputGroup className="mt-3">
        <FormLabel title={"Link"} required />
        <ReactInput name="link" required />
      </InputGroup>

      <InputGroup className="mt-3 d-flex align-items-center gap-4">
        <FormLabel title={"Live Start"} />
        <input
          type="datetime-local"
          className="form-control"
          value={formatDateForInput(values?.live_start)}
          onChange={(e) =>
            setFieldValue("live_start", new Date(e.target.value))
          }
          // format="Asia/Dhaka"
        />
      </InputGroup>

      <InputGroup className="mt-3 d-flex align-items-center gap-4">
        <FormLabel title={"Live End"} />
        <input
          type="datetime-local"
          className="form-control"
          value={formatDateForInput(values?.live_end)}
          onChange={(e) => setFieldValue("live_end", new Date(e.target.value))}
          // onChange={(e) => {
          //   const localDate = new Date(e.target.value);
          //   console.log(localDate);
          // }}
        />
      </InputGroup>

      <InputGroup className="mt-3">
        <Form.Check
          label="status"
          checked={values.status === 1}
          onChange={(e) => setFieldValue("status", e.target.checked ? 1 : 0)}
        />
      </InputGroup>
    </div>
  );
};
export default SocialMediaForm;
