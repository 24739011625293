import { Card, Form, Table } from "react-bootstrap";
import CardLayout from "../../components/Layout/CardLayout";
import FormLayout from "../../components/Layout/FormLayout";
import { FieldArray, useFormikContext } from "formik";
import ReactSelect from "../../components/ReactSelect/ReactSelect";
import ReactInput from "../../components/InputList/ReactInput/ReactInput";
import useFetch from "../../hooks/useFetch";
import request from "../../request/request";
import LoaderLayout from "../../components/Loader/LoaderLayout";
import toast from "react-hot-toast";

const PackageForServiceCenter = ({ serviceCenterList, packageId }) => {
  const { result, isLoading, setRefresh } = useFetch(() =>
    request.getAllById("package-management/service-center", packageId)
  );
  const service_center_list = result?.service_center_list || [];
  const initialValues = {
    service_center_list: service_center_list,
    // service_center_list: [
    //   {
    //     service_center_id: "",
    //     package_id: packageId,
    //     price: "",
    //     status: true,
    //   },
    // ],
  };

  const submitHandler = (values) => {
    const data = {
      service_center_list: values.service_center_list.filter(
        (item) => !!item.service_center_id
      ),
    };
    // console.log(data);
    // return null;
    return request.updateOne(
      "package-management/service-center",
      packageId,
      data,
      () => {
        setRefresh((prev) => !prev);
      }
    );
  };

  return (
    <Card body>
      <LoaderLayout isPending={isLoading}>
        <FormLayout
          initialValues={initialValues}
          apiHandler={submitHandler}
          enableReinitialize={true}
        >
          <FieldArray
            name="service_center_list"
            render={({ push, remove }) => (
              <div>
                {/* <button type="button" onClick={() => push({})}>
                Add
              </button> */}
                <ServiceCenterList
                  serviceCenterList={serviceCenterList}
                  remove={remove}
                  push={push}
                  packageId={packageId}
                />
              </div>
            )}
          />
        </FormLayout>
      </LoaderLayout>
    </Card>
  );
};

export default PackageForServiceCenter;

const ServiceCenterList = ({ serviceCenterList, remove, push, packageId }) => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <CardLayout
      title={"Service Center List For Package"}
      buttonTitle={"Add"}
      addButtonHandler={() =>
        push({
          service_center_id: "",
          package_id: packageId,
          price: "",
          status: true,
        })
      }
    >
      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ width: "40%" }}>Service Center</th>
            <th>Price</th>
            <th>Discount Price</th>
            <th>Points</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {values.service_center_list.map((item, index) => (
            <tr key={item.id}>
              <td>
                <ReactSelect
                  data={serviceCenterList}
                  valueName={"id"}
                  labelName={"f_name"}
                  placeholder="Select Service Center"
                  value={item.service_center_id}
                  onChange={(value) => {
                    // console.log(value);
                    setFieldValue(
                      `service_center_list.${index}.service_center_id`,
                      value
                    );
                  }}
                />
              </td>
              <td>
                <ReactInput
                  type="number"
                  name={`service_center_list.${index}.price`}
                  placeholder="Price"
                />
              </td>

              <td>
                <ReactInput
                  type="number"
                  name={`service_center_list.${index}.discount_price`}
                  placeholder="Discount Price"
                />
              </td>

              <td>
                <ReactInput
                  type="number"
                  name={`service_center_list.${index}.points`}
                  placeholder="Points"
                />
              </td>

              <td>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    checked={item.status}
                    onChange={(e) => {
                      setFieldValue(
                        `service_center_list.${index}.status`,
                        e.target.checked
                      );
                    }}
                  />
                </Form.Group>
              </td>
              <td>
                <button
                  className="btn btn-danger"
                  type="button"
                  onClick={() => remove(index)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </CardLayout>
  );
};
