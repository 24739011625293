import { Button, Card } from "react-bootstrap";
import React from "react";
import { FaPlus } from "react-icons/fa";

const CardLayout = ({ children, addButtonHandler, title, buttonTitle }) => (
  <Card className="border p-3 rounded">
    <Card.Body>
      <div className="card-box d-flex justify-content-between align-items-center">
        <Card.Title className="mb-0 text-uppercase">{title}</Card.Title>
        {buttonTitle && (
          <div className="">
            <Button
              onClick={addButtonHandler}
              className="d-flex align-items-center gap-2"
              variant="primary"
            >
              {" "}
              <FaPlus /> {buttonTitle}
            </Button>
          </div>
        )}
      </div>
      <hr />
      {children}
    </Card.Body>
  </Card>
);

export default CardLayout;
