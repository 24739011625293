import { Link, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import CardLayout from "../../../components/Layout/CardLayout";
import LoaderLayout from "../../../components/Loader/LoaderLayout";
import useFetch from "../../../hooks/useFetch";
import request from "../../../request/request";

const PageDetails = () => {
  const { id } = useParams();

  const { result, isLoading } = useFetch(
    () => request.getOne("dynamic-pages/get-one", id),
    id
  );

  console.log(result);
  return (
    <LoaderLayout isPending={isLoading}>
      <CardLayout title="Page Details">
        {result?.left_menu?.length > 0 ? (
          <div>
            <Row>
              <Col sm={4}>
                <ul>
                  {result?.left_menu?.map((item) => (
                    <li key={item?.id}>
                      <Link href={item?.url}>{item?.name}</Link>
                    </li>
                  ))}
                </ul>
              </Col>

              <Col sm={8}>
                <div
                  className="dynamic-description"
                  dangerouslySetInnerHTML={{ __html: result?.content }}
                />
              </Col>
            </Row>
          </div>
        ) : (
          <div
            className="dynamic-description"
            dangerouslySetInnerHTML={{ __html: result?.content }}
          />
        )}
      </CardLayout>
    </LoaderLayout>
  );
};

export default PageDetails;
